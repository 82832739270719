import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        padding: "40px",
        fontFamily: "'Arial', sans-serif",
        color: "#ffff",
        backgroundColor: "#121212",
        minHeight: "100vh",
      }}
    >
      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#f2b20f" }}>
          Privacy Policy
        </h1>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          Welcome to <strong>Just Chicken Wings</strong>, a concept powered by <strong>Simple Virtual Kitchen</strong>. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services, including delivery services provided by <strong>Hey Jag</strong>.
        </p>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          1. Information We Collect
        </h2>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px", marginBottom: "20px" }}>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone number, and delivery details when you register or place an order.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you use our website and apps, such as IP address, browser type, and session duration.
          </li>
        </ul>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          2. How We Use Your Information
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          We use your information to:
        </p>
        <ul style={{ listStyleType: "circle", paddingLeft: "20px", marginBottom: "20px" }}>
          <li>Provide and improve our services and virtual kitchen concepts.</li>
          <li>Process orders and facilitate delivery through <strong>Hey Jag</strong>.</li>
          <li>Send updates, promotions, and notifications.</li>
          <li>Enhance user experience through analytics.</li>
        </ul>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          3. Sharing Your Information
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          We do not sell or rent your personal information. However, we may share it with trusted third-party partners, such as payment processors and delivery platforms like <strong>Hey Jag</strong>, to fulfill your orders and improve services.
        </p>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          4. Security
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          We take reasonable measures to protect your data but cannot guarantee absolute security due to the nature of the internet.
        </p>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          5. Your Rights
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          You have the right to access, update, or delete your information. To exercise these rights, contact us using the details below:
        </p>
        <address style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          350 N Federal Hwy, Boynton Beach FL, 33435 <br />
          <a href="mailto:contact@simplevirtualkitchen.com" style={{ color: "#1a73e8" }}>
            contact@simplevirtualkitchen.com
          </a>
          <br />
          (561) 319-0974
        </address>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          6. Changes to This Policy
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          We may update this Privacy Policy from time to time. Any changes will be reflected on this page with an updated date.
        </p>

        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px", fontStyle: "italic" }}>
          Effective Date: January 25, 2025
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

