import React from "react";

const TermsAndConditions = () => {
  return (
    <div
      style={{
        padding: "40px",
        fontFamily: "'Arial', sans-serif",
        color: "#ffff",
        backgroundColor: "#121212",
        minHeight: "100vh",
      }}
    >
      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#f2b20f" }}>
          Terms and Conditions
        </h1>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          These Terms and Conditions govern your use of <strong>Just Chicken Wings</strong>, a concept of <strong>Simple Virtual Kitchen</strong>. By accessing or using our services, including delivery by <strong>Hey Jag</strong>, you agree to the following terms:
        </p>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          1. Services Provided
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          We provide food prepared through our virtual kitchen concepts and deliver it through <strong>Hey Jag</strong>. Use of these services is subject to availability.
        </p>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          2. Payments and Refunds
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          All payments are processed through secure third-party payment platforms. Refunds are provided in accordance with our refund policy, which is available upon request.
        </p>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          3. Limitation of Liability
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          We are not liable for delays or disruptions in service caused by third-party providers, including <strong>Hey Jag</strong>.
        </p>

        <h2 style={{ fontSize: "1.8rem", marginBottom: "15px", color: "#f2b20f" }}>
          4. Changes to Terms
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          We may update these Terms and Conditions at any time. Changes will be effective immediately upon posting.
        </p>

        <address style={{ fontSize: "1.1rem", lineHeight: "1.8", marginBottom: "20px" }}>
          Contact Us: <br />
          350 N Federal Hwy, Boynton Beach FL, 33435 <br />
          <a href="mailto:contact@simplevirtualkitchen.com" style={{ color: "#1a73e8" }}>
            contact@simplevirtualkitchen.com
          </a>
          <br />
          (561) 319-0974
        </address>
      </div>
    </div>
  );
};

export default TermsAndConditions;
